import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=4c21301b&"
import script from "./AppFooter.vue?vue&type=script&lang=ts&"
export * from "./AppFooter.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/usr/app/components/cms/page/Footer.vue').default})
