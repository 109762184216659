import { computed, reactive, readonly, ref, useContext, useRoute, } from '@nuxtjs/composition-api';
import { addItemCommand } from '~/modules/checkout/composables/useCart/commands/addItemCommand';
import { applyCouponCommand } from '~/modules/checkout/composables/useCart/commands/applyCouponCommand';
import { loadCartCommand } from '~/modules/checkout/composables/useCart/commands/loadCartCommand';
import { loadTotalQtyCommand } from '~/modules/checkout/composables/useCart/commands/loadTotalQtyCommand';
import { removeCouponCommand } from '~/modules/checkout/composables/useCart/commands/removeCouponCommand';
import { removeItemCommand } from '~/modules/checkout/composables/useCart/commands/removeItemCommand';
import { updateItemQtyCommand } from '~/modules/checkout/composables/useCart/commands/updateItemQtyCommand';
import { Logger } from '~/helpers/logger';
import { ProductStockStatus, } from '~/modules/GraphQL/types';
import { useCartStore } from '~/modules/checkout/stores/cart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { updateCartItemsCommand } from './commands/updateCartItemsCommand';
// to have a single instance for loading state to all useCart composable instances
const localState = reactive({
    loading: false,
});
/**
 * Allows loading and manipulating cart of the current user.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */
export function useCart() {
    const loading = computed(() => localState.loading);
    const error = ref({
        addItem: null,
        removeItem: null,
        updateItemQty: null,
        load: null,
        clear: null,
        applyCoupon: null,
        removeCoupon: null,
        loadTotalQty: null,
    });
    const { app, $cookies } = useContext();
    const context = app.$vsf;
    const route = useRoute();
    const cartStore = useCartStore();
    const cart = computed(() => cartStore.cart);
    const apiState = context.$magento.config.state;
    const { loading: wishlistLoading, afterAddingWishlistItemToCart } = useWishlist();
    const cartType = computed(() => {
        var _a, _b;
        if ((_b = (_a = cart.value) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.length) {
            const cartItemsSkusSet = new Set(cart.value.items.map((item) => item.product.sku));
            return cartItemsSkusSet.size === 1 ? cartItemsSkusSet.values().next().value : 'mixed';
        }
        return null;
    });
    /**
     * Assign new cart object
     * @param newCart
     *
     * @return void
     */
    const setCart = (newCart) => {
        Logger.debug('useCart.setCart', newCart);
        cartStore.$patch((state) => {
            state.cart = newCart;
        });
    };
    /**
     * Check if product is in the cart
     * @param product
     *
     * @return boolean
     */
    const isInCart = (product) => { var _a, _b; return !!((_b = (_a = cart.value) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.find((cartItem) => { var _a; return ((_a = cartItem === null || cartItem === void 0 ? void 0 : cartItem.product) === null || _a === void 0 ? void 0 : _a.uid) === product.uid; })); };
    const load = async ({ customQuery = {}, customHeaders = {}, realCart = false } = { customQuery: { cart: 'cart' }, customHeaders: {} }) => {
        Logger.debug('useCart.load');
        try {
            localState.loading = true;
            const loadedCart = await loadCartCommand.execute(context, { customQuery, customHeaders, realCart });
            cartStore.$patch((state) => {
                state.cart = loadedCart;
            });
            error.value.load = null;
        }
        catch (err) {
            // check if cart returns an error with message "Can't assign cart to store in different website."
            // we should empty session storage and try to load cart again
            console.log({ error });
            error.value.load = err;
            Logger.error('useCart/load', err);
        }
        finally {
            localState.loading = false;
        }
    };
    const clear = async ({ customQuery, customHeaders } = { customQuery: { cart: 'cart' }, customHeaders: {} }) => {
        Logger.debug('useCart.clear');
        try {
            localState.loading = true;
            context.$magento.config.state.removeCartId();
            const loadedCart = await loadCartCommand.execute(context, { customQuery, customHeaders });
            cartStore.$patch((state) => {
                state.cart = loadedCart;
            });
        }
        catch (err) {
            error.value.clear = err;
            Logger.error('useCart/clear', err);
        }
        finally {
            localState.loading = false;
        }
    };
    const loadTotalQty = async (params) => {
        Logger.debug('useCart.loadTotalQty');
        try {
            localState.loading = true;
            const totalQuantity = await loadTotalQtyCommand.execute(context, params);
            cartStore.$patch((state) => {
                state.cart.total_quantity = totalQuantity;
            });
        }
        catch (err) {
            error.value.loadTotalQty = err;
            Logger.error('useCart/loadTotalQty', err);
        }
        finally {
            localState.loading = false;
        }
    };
    const addItem = async ({ product, quantity, productConfiguration, customQuery, customHeaders, }) => {
        var _a;
        Logger.debug('useCart.addItem', { product, quantity });
        try {
            localState.loading = true;
            if (!apiState.getCartId()) {
                await load({ realCart: true });
            }
            const updatedCart = await addItemCommand.execute(context, {
                currentCart: cart.value,
                product,
                quantity,
                productConfiguration,
                customQuery: { ...customQuery, addProductsToCart: 'addProductsToCart' },
                customHeaders,
            });
            error.value.addItem = null;
            cartStore.$patch((state) => {
                state.cart = updatedCart;
            });
        }
        catch (err) {
            error.value.addItem = err;
            Logger.error('useCart/addItem', err);
        }
        finally {
            if (!wishlistLoading.value && ((_a = route.value.query) === null || _a === void 0 ? void 0 : _a.wishlist)) {
                afterAddingWishlistItemToCart({
                    product,
                    cartError: error.value.addItem,
                });
            }
            localState.loading = false;
        }
    };
    const removeItem = async ({ product, customQuery, customHeaders }) => {
        Logger.debug('useCart.removeItem', { product });
        try {
            localState.loading = true;
            const updatedCart = await removeItemCommand.execute(context, {
                currentCart: cart.value,
                product,
                customQuery: { ...customQuery, removeItemFromCart: 'removeItemFromCart' },
                customHeaders,
            });
            error.value.removeItem = null;
            cartStore.$patch((state) => {
                state.cart = updatedCart;
            });
        }
        catch (err) {
            error.value.removeItem = err;
            Logger.error('useCart/removeItem', err);
        }
        finally {
            localState.loading = false;
        }
    };
    const updateItemQty = async ({ product, quantity, customQuery = { updateCartItems: 'updateCartItems' } }) => {
        Logger.debug('useCart.updateItemQty', {
            product,
            quantity,
        });
        if (quantity && quantity > 0) {
            try {
                localState.loading = true;
                const updatedCart = await updateItemQtyCommand.execute(context, {
                    currentCart: cart.value,
                    product,
                    quantity,
                    customQuery,
                });
                error.value.updateItemQty = null;
                cartStore.$patch((state) => {
                    state.cart = updatedCart;
                });
            }
            catch (err) {
                error.value.updateItemQty = err;
                Logger.error('useCart/updateItemQty', err);
            }
            finally {
                localState.loading = false;
            }
        }
    };
    const updateCartItem = async ({ product, updates, customQuery = { updateCartItems: 'updateCartItems' } }) => {
        Logger.debug('useCart.updateItemQty', {
            product,
            updates,
        });
        if (updates && updates.quantity > 0) {
            try {
                localState.loading = true;
                const updatedCart = await updateCartItemsCommand.execute(context, {
                    currentCart: cart.value,
                    product,
                    updates,
                    customQuery,
                });
                error.value.updateItemQty = null;
                cartStore.$patch((state) => {
                    state.cart = updatedCart;
                });
            }
            catch (err) {
                error.value.updateItemQty = err;
                Logger.error('useCart/updateItemQty', err);
            }
            finally {
                localState.loading = false;
            }
        }
    };
    const handleCoupon = async (couponCode = null, customQuery = null) => {
        const variables = {
            currentCart: cart.value,
            customQuery,
            couponCode,
        };
        const { updatedCart, errors } = couponCode
            ? await applyCouponCommand.execute(context, variables)
            : await removeCouponCommand.execute(context, variables);
        if (errors) {
            throw errors[0];
        }
        if (updatedCart) {
            cartStore.$patch((state) => {
                state.cart = updatedCart;
            });
        }
    };
    const applyCoupon = async ({ couponCode, customQuery }) => {
        Logger.debug('useCart.applyCoupon');
        try {
            localState.loading = true;
            await handleCoupon(couponCode, customQuery);
            error.value.applyCoupon = null;
        }
        catch (err) {
            error.value.applyCoupon = err;
            Logger.error('useCart/applyCoupon', err);
        }
        finally {
            localState.loading = false;
        }
    };
    const removeCoupon = async ({ customQuery }) => {
        Logger.debug('useCart.removeCoupon');
        try {
            localState.loading = true;
            await handleCoupon(null, customQuery);
            error.value.applyCoupon = null;
        }
        catch (err) {
            error.value.removeCoupon = err;
            Logger.error('useCart/removeCoupon', err);
        }
        finally {
            localState.loading = false;
        }
    };
    const canAddToCart = (product, qty = 1) => {
        var _a, _b;
        // eslint-disable-next-line no-underscore-dangle
        if ((product === null || product === void 0 ? void 0 : product.__typename) === 'ConfigurableProduct') {
            return !!((_b = (_a = product === null || product === void 0 ? void 0 : product.configurable_product_options_selection) === null || _a === void 0 ? void 0 : _a.variant) === null || _b === void 0 ? void 0 : _b.uid);
        }
        const inStock = (product === null || product === void 0 ? void 0 : product.stock_status) === ProductStockStatus.InStock;
        const stockLeft = (product === null || product === void 0 ? void 0 : product.only_x_left_in_stock) === null
            ? true
            : qty <= (product === null || product === void 0 ? void 0 : product.only_x_left_in_stock);
        return inStock && stockLeft;
    };
    return {
        setCart,
        cart,
        loadTotalQty,
        isInCart,
        addItem,
        load,
        removeItem,
        clear,
        updateItemQty,
        updateCartItem,
        applyCoupon,
        removeCoupon,
        canAddToCart,
        loading: readonly(loading),
        error: readonly(error),
        cartType,
    };
}
export default useCart;
export * from './useCart';
