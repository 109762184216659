export const PENDING_PRESCRIPTION_BUNDLE_OPTION_LABEL = 'PendingPrescription';
export const PROVIDE_PRESCRIPTION_LATER_CARD_ID = 'prescription_providelater';
export const ENTER_PRESCRIPTION_MANUALLY_CARD_ID = 'prescription_entermanually';
export const QUEST_3_BUNDLE_SKU = 'vr-lens-bundle';
export const QUEST_GOOSE_BUNDLE_SKU = 'vr-lens-bundle-goose';
export const NON_PRESCRIPTION_CARD_ID = 'vr-lens-plano';
export const PRESCRIPTION_CARD_ID = 'vr-lens-prescription';
export const NON_PRESCRIPTION_ANTI_FOG_CARD_ID = 'vr-lens-plano-anti-fog';
export const NON_PRESCRIPTION_OLEO_CARD_ID = 'vr-lens-plano-oleo';
export const ANTI_FOG_CARD_ID = 'anti-fog';
export const OLEO_CARD_ID = 'oleophobic-anti-reflective-coating';
export const NON_PRESCRIPTION_GOOSE_CARD_ID = 'vr-lens-plano-goose-oleo';
export const PRESCRIPTION_GOOSE_CARD_ID = 'vr-lens-prescription-goose-oleo';
export const PRESCRIPTION_ANTI_FOG_GOOSE_CARD_ID = 'vr-lens-prescription-goose-anti-fog';
export const NON_PRESCRIPTION_ANTI_FOG_GOOSE_CARD_ID = 'vr-lens-plano-goose-anti-fog';
export const NON_PRESCRIPTION_OLEO_GOOSE_CARD_ID = 'vr-lens-plano-goose-oleo';
export const ANTI_FOG_GOOSE_CARD_ID = 'anti-fog-goose';
export const OLEO_CARD_GOOSE_ID = 'oleophobic-anti-reflective-coating-goose';
export const IS_PRESCRIPTION_LENS_BUNDLE_OPTION_LABEL = 'IsPrescriptionLens';
export const ITEM_ID_BUNDLE_OPTION_LABEL = 'id';
// if true, then the frame size is small and VR7000601 should be used
const isSphCylSmall = (sph, cyl) => {
    const sphConverted = cyl > 0 ? sph + cyl : sph;
    const cylConverted = cyl > 0 ? -cyl : cyl;
    if (sphConverted <= 0)
        return true;
    if (sphConverted >= 2.25)
        return false;
    if (cylConverted <= -2.25)
        return false;
    return true;
};
export const gooseFrameSize = (prescription) => {
    const odSphere = typeof prescription.od.sphere === 'number' ? prescription.od.sphere : Number.parseFloat(prescription.od.sphere);
    const osSphere = typeof prescription.os.sphere === 'number' ? prescription.os.sphere : Number.parseFloat(prescription.os.sphere);
    const odCylinders = typeof prescription.od.cylinders === 'number' ? prescription.od.cylinders : Number.parseFloat(prescription.od.cylinders);
    const osCylinders = typeof prescription.os.cylinders === 'number' ? prescription.os.cylinders : Number.parseFloat(prescription.os.cylinders);
    const isOdFrameSmall = isSphCylSmall(odSphere, odCylinders);
    const isOsFrameSmall = isSphCylSmall(osSphere, osCylinders);
    return (isOdFrameSmall && isOsFrameSmall) ? 'VR7000601' : 'VR7000801';
};
export const BUNDLE_OPTIONS_RULES = {
    [PRESCRIPTION_CARD_ID]: {
        [OLEO_CARD_ID]: {
            lens: PRESCRIPTION_CARD_ID,
            frame: () => 'VR7000301',
        },
        [ANTI_FOG_CARD_ID]: {
            lens: PRESCRIPTION_CARD_ID,
            frame: () => 'VR7000701',
        },
    },
    [NON_PRESCRIPTION_CARD_ID]: {
        [OLEO_CARD_ID]: {
            lens: NON_PRESCRIPTION_OLEO_CARD_ID,
            frame: () => 'VR7000401',
        },
        [ANTI_FOG_CARD_ID]: {
            lens: NON_PRESCRIPTION_ANTI_FOG_CARD_ID,
            frame: () => 'VR7000501',
        },
    },
    // Prescription lenses size dependent on the prescription values
    [PRESCRIPTION_GOOSE_CARD_ID]: {
        [OLEO_CARD_GOOSE_ID]: {
            lens: PRESCRIPTION_GOOSE_CARD_ID,
            frame: gooseFrameSize,
        },
        [ANTI_FOG_GOOSE_CARD_ID]: {
            lens: PRESCRIPTION_ANTI_FOG_GOOSE_CARD_ID,
            frame: gooseFrameSize,
        },
    },
    // Non prescription lenses size will always be standard size
    [NON_PRESCRIPTION_GOOSE_CARD_ID]: {
        [OLEO_CARD_GOOSE_ID]: {
            lens: NON_PRESCRIPTION_OLEO_GOOSE_CARD_ID,
            frame: () => 'VR7000601',
        },
        [ANTI_FOG_GOOSE_CARD_ID]: {
            lens: NON_PRESCRIPTION_ANTI_FOG_GOOSE_CARD_ID,
            frame: () => 'VR7000601',
        },
    },
};
export const REVIEW_SKU_MAP = {
    [QUEST_3_BUNDLE_SKU]: 'VR7000301',
};
export const MIN_REVIEWS_TO_SHOW = 10;
export const SUPPORTED_CARDS_BY_STORE = {
    US: ['visa', 'master-card', 'american-express', 'discover', 'jcb', 'diners-club', 'unionpay'],
    CA: ['visa', 'master-card', 'american-express'],
    GB: ['visa', 'master-card', 'american-express', 'discover', 'jcb', 'diners-club', 'maestro'],
    EU: ['visa', 'master-card', 'american-express', 'discover', 'jcb', 'diners-club', 'maestro'],
    JP: ['visa', 'master-card', 'american-express'],
    AU: ['visa', 'master-card', 'american-express', 'diners-club'],
};
export const PAYMENT_GATEWAYS_MAP = {
    cc: 'cc',
    gpay: 'gpay',
    applepay: 'applepay',
    paypal: 'paypal',
    GOOGLE_PAY: 'gpay',
    PAYPAL: 'paypal',
    APPLE_PAY: 'applepay',
    CREDIT_CARD: 'cc',
};
// as this is client we define the currency mapper here.
// Be careful middleware.config.js also have a mapper that is used in the middleware
// and should be kept in sync with this one.
export const STORE_CURRENCY_MAPPER = {
    us: 'USD',
    gb: 'GBP',
    jp: 'JPY',
    ca: 'CAD',
    eu: 'EUR',
    au: 'AUD',
};
export const raskullVideoUrl = {
    embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/e8a2edc1ec6d2c67a7008c361e334623/iframe?preload=true&poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2Fe8a2edc1ec6d2c67a7008c361e334623%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26s',
    mp4: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/e8a2edc1ec6d2c67a7008c361e334623/downloads/default.mp4',
    videoPoster: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/e8a2edc1ec6d2c67a7008c361e334623/thumbnails/thumbnail.jpg?time=5s',
};
export const antiFogVideoUrl = {
    embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/165bfb9bf43577cbb086f3bdf5eeaa85/iframe?poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2F165bfb9bf43577cbb086f3bdf5eeaa85%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26',
    mp4: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/165bfb9bf43577cbb086f3bdf5eeaa85/downloads/default.mp4',
    videoPoster: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/165bfb9bf43577cbb086f3bdf5eeaa85/thumbnails/thumbnail.jpg?time=5s',
};
export const localizedUrls = {
    [QUEST_3_BUNDLE_SKU]: {
        en: {
            embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/5d2fdbc148f24cdd0a4d7d3c48a2109c/iframe?preload=true&poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%5d2fdbc148f24cdd0a4d7d3c48a2109c%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D3s',
            mp4: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/5d2fdbc148f24cdd0a4d7d3c48a2109c/downloads/default.mp4',
            videoPoster: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/5d2fdbc148f24cdd0a4d7d3c48a2109c/thumbnails/thumbnail.jpg?time=3s',
        },
        ja: {
            embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/5fd482b83dc482424292af82516615e0/iframe?preload=true&poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2F5fd482b83dc482424292af82516615e0%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D5s',
            mp4: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/5fd482b83dc482424292af82516615e0/downloads/default.mp4',
            videoPoster: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/5fd482b83dc482424292af82516615e0/thumbnails/thumbnail.jpg?time=5s',
        },
        fr: {
            embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/773a83b0691ed69e6eaa5ed8348db382/iframe?preload=true&poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2F773a83b0691ed69e6eaa5ed8348db382%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D5s',
            mp4: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/773a83b0691ed69e6eaa5ed8348db382/downloads/default.mp4',
            videoPoster: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/773a83b0691ed69e6eaa5ed8348db382/thumbnails/thumbnail.jpg?time=5s',
        },
        de: {
            embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/f1dbec032455683a94a316a32cc453fe/iframe?preload=true&poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2Ff1dbec032455683a94a316a32cc453fe%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D5s',
            mp4: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/f1dbec032455683a94a316a32cc453fe/downloads/default.mp4',
            videoPoster: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/f1dbec032455683a94a316a32cc453fe/thumbnails/thumbnail.jpg?time=5s',
        },
    },
    [QUEST_GOOSE_BUNDLE_SKU]: {
        en: {
            embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/4fcdc40c762f9e6729eb2ff18c568ddb/iframe?poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2F4fcdc40c762f9e6729eb2ff18c568ddb%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
            mp4: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/4fcdc40c762f9e6729eb2ff18c568ddb/downloads/default.mp4',
            videoPoster: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/4fcdc40c762f9e6729eb2ff18c568ddb/thumbnails/thumbnail.jpg?time=5s',
        },
        ja: {
            embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/726a77b18589595257df3319b7cbef46/iframe?poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2F726a77b18589595257df3319b7cbef46%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
            mp4: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/726a77b18589595257df3319b7cbef46/downloads/default.mp4',
            videoPoster: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/726a77b18589595257df3319b7cbef46/thumbnails/thumbnail.jpg?time=5s',
        },
        fr: {
            embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/4fbd3f2426dda4a50d17ac55d53c27d8/iframe?poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2F4fbd3f2426dda4a50d17ac55d53c27d8%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
            mp4: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/4fbd3f2426dda4a50d17ac55d53c27d8/downloads/default.mp4',
            videoPoster: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/4fbd3f2426dda4a50d17ac55d53c27d8/thumbnails/thumbnail.jpg?time=5s',
        },
        de: {
            embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/b89b3a814f49f80aa6586a49f4eaacff/iframe?poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2Fb89b3a814f49f80aa6586a49f4eaacff%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
            mp4: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/b89b3a814f49f80aa6586a49f4eaacff/downloads/default.mp4',
            videoPoster: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/b89b3a814f49f80aa6586a49f4eaacff/thumbnails/thumbnail.jpg?time=5s',
        },
    },
};
export const INSTALL_VIREO_URL_MAP = {
    [QUEST_3_BUNDLE_SKU]: raskullVideoUrl,
};
export const VOUCHER_ORDER_TYPE = 'Voucher';
export const productItemLabelsToHide = [
    'configurable prescription frame 301',
    'configurable prescription frame 401',
    'configurable prescription frame 501',
    'configurable prescription frame 601',
    'configurable prescription frame 701',
    'configurable prescription frame 801',
];
export const GTM_EVENTS = {
    EMAIL_VERIFIED_LOGIN: 'email_verified_login',
    VOUCHER_CONFIRM_PRESCRIPTION: 'voucher_confirm_prescription',
    VOUCHER_PLACE_ORDER: 'voucher_place_order',
    VOUCHER_ENTER_PRESCRIPTION: 'voucher_enter_prescription',
    SHOP_AT_ZENNI_CLICK: 'shop_at_zenni_click',
};
export const VR_FLOW_PARTNER_ID = 'beast.default.us';
export const VOUCHER_FLOW_PARTNER_ID = 'beast.voucher.us';
export const GOOSE_VR_FLOW_PARTNER_ID = 'goose.default.us';
export const GOOSE_VOUCHER_FLOW_PARTNER_ID = 'goose.voucher.us';
export const PARTNER_ID_MAPS = {
    [QUEST_3_BUNDLE_SKU]: {
        default: VR_FLOW_PARTNER_ID,
        voucher: VOUCHER_FLOW_PARTNER_ID,
    },
    [QUEST_GOOSE_BUNDLE_SKU]: {
        default: GOOSE_VR_FLOW_PARTNER_ID,
        voucher: GOOSE_VOUCHER_FLOW_PARTNER_ID,
    },
};
export const PRE_ORDER_DATES = {
    [QUEST_3_BUNDLE_SKU]: () => '2023-10-10T00:00:00.000-07:00',
    [QUEST_GOOSE_BUNDLE_SKU]: (store) => {
        // EU store means Europe zone, but we need to get the specific country
        // We are using AT as default country for EU store
        const storeToGet = store === 'eu' ? 'at' : store;
        // there is only one cutoff date for all stores and as we are using timezone in the date, there is no need to convert it
        // to the specific timezone of the store. Note: this remains as commented in case we need to use it in the future
        const dates = {
            ALL: '2024-10-15T00:00:00-08:00',
            // AT: '2024-10-14T15:00:00-07:00',
            // AU: '2024-10-14T07:00:00-07:00',
            // BE: '2024-10-14T15:00:00-07:00',
            // CA: '2024-10-14T21:00:00-07:00',
            // CH: '2024-10-14T15:00:00-07:00',
            // DE: '2024-10-14T15:00:00-07:00',
            // DK: '2024-10-14T15:00:00-07:00',
            // ES: '2024-10-14T15:00:00-07:00',
            // FI: '2024-10-14T15:00:00-07:00',
            // FR: '2024-10-14T15:00:00-07:00',
            // IE: '2024-10-14T16:00:00-07:00',
            // IS: '2024-10-14T16:00:00-07:00',
            // IT: '2024-10-14T15:00:00-07:00',
            // JP: '2024-10-14T08:00:00-07:00',
            // KR: '2024-10-14T08:00:00-07:00',
            // NL: '2024-10-14T15:00:00-07:00',
            // NO: '2024-10-14T15:00:00-07:00',
            // NZ: '2024-10-14T07:00:00-07:00',
            // PL: '2024-10-14T15:00:00-07:00',
            // SE: '2024-10-14T15:00:00-07:00',
            // TW: '2024-10-14T08:00:00-07:00',
            // UK: '2024-10-14T16:00:00-07:00',
            // US: '2024-10-14T21:00:00-07:00',
        };
        return dates[storeToGet === null || storeToGet === void 0 ? void 0 : storeToGet.toUpperCase()] || dates.ALL;
    },
};
export const RX_RANGES = {
    [QUEST_3_BUNDLE_SKU]: {
        COMBINATION_RANGE: {
            MIN: -9,
            MAX: 6,
        },
        CYLINDERS_RANGE: {
            MIN: -4,
            MAX: 4,
        },
    },
    [QUEST_GOOSE_BUNDLE_SKU]: {
        COMBINATION_RANGE: {
            MIN: -10,
            MAX: 6,
        },
        CYLINDERS_RANGE: {
            MIN: -6,
            MAX: 6,
        },
    },
    default: {
        COMBINATION_RANGE: {
            MIN: -9,
            MAX: 6,
        },
        CYLINDERS_RANGE: {
            MIN: -4,
            MAX: 4,
        },
    },
};
// we need this to ensure show the correct tags before the product info is fetched
export const SEO_META_TAGS_BY_PRODUCT = {
    [QUEST_3_BUNDLE_SKU]: {
        title: 'VR Prescription Lenses for Meta Quest 3',
        meta: [
            { hid: 'keywords', name: 'keywords', content: 'VR Prescription Lenses for Meta Quest 3' },
            { hid: 'description', name: 'description', content: 'Watch your virtual world come to life without sacrificing comfort with VR prescription lenses for Meta Quest 3' },
        ],
    },
    [QUEST_GOOSE_BUNDLE_SKU]: {
        title: 'MR Prescription Lenses for Meta Quest 3S / 2',
        meta: [
            { hid: 'keywords', name: 'keywords', content: 'MR Prescription Lenses for Meta Quest 3S / 2' },
            { hid: 'description', name: 'description', content: 'Watch your virtual world come to life without sacrificing comfort with MR prescription lenses for Meta Quest 3S / 2' },
        ],
    },
};
