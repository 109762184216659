import { defineComponent, ref, computed, } from '@nuxtjs/composition-api';
import { isMobile, isSafari } from 'mobile-device-detect';
import { useUrlLanguageParser } from '~/modules/b2b/localization';
import { localizedUrls, QUEST_3_BUNDLE_SKU } from '~/modules/b2b/constants.client';
export default defineComponent({
    name: 'InstallVideo',
    props: {
        noControls: {
            type: Boolean,
            default: false,
        },
        muted: {
            type: Boolean,
            default: false,
        },
        loop: {
            type: Boolean,
            default: true,
        },
        forceVideoTag: {
            type: Boolean,
            default: false,
        },
        customUrl: {
            type: Object,
            default: undefined,
        },
        sku: {
            type: String,
            default: QUEST_3_BUNDLE_SKU,
            required: false,
        },
    },
    setup(props) {
        const { languageCode } = useUrlLanguageParser();
        const videoPlayer = ref(null);
        const urls = computed(() => {
            var _a;
            if (props.customUrl) {
                return props.customUrl;
            }
            return props.sku
                ? (((_a = localizedUrls[props.sku][languageCode]) !== null && _a !== void 0 ? _a : localizedUrls[props.sku].en))
                : localizedUrls[QUEST_3_BUNDLE_SKU].en;
        });
        const dynamicSrc = computed(() => `${urls.value.embed}${props.noControls ? '&controls=false' : ''}${props.muted ? '&muted=true' : ''}`);
        // There is a known issue using cloudflare stream with mobile safari
        // https://community.cloudflare.com/t/mp4-wont-load-in-safari-using-cloudflare/10587
        const isMobileAndSafari = computed(() => isMobile && isSafari);
        const play = () => {
            if (videoPlayer.value) {
                videoPlayer.value.play();
            }
        };
        const pause = () => {
            if (videoPlayer.value) {
                videoPlayer.value.pause();
            }
        };
        return {
            urls,
            play,
            pause,
            isMobileAndSafari,
            videoPlayer,
            dynamicSrc,
        };
    },
    head() {
        return {
            script: [
                {
                    src: 'https://embed.cloudflarestream.com/embed/sdk.latest.js',
                    body: true,
                },
            ],
        };
    },
});
