import { defineComponent, computed } from '@nuxtjs/composition-api';
import { ZnButton } from '~/modules/b2b/components';
import { useUrlLanguageParser } from '~/modules/b2b/localization/composables';
import { useOrder } from '~/modules/b2b/checkout/composables';
import { GTM_EVENTS } from '~/modules/b2b/constants.client';
export default defineComponent({
    name: 'ZnPromoCard',
    components: {
        ZnButton,
    },
    props: {
        promoCode: {
            type: String,
            default: 'loading...',
        },
    },
    setup() {
        const { sendGTMEvent } = useOrder();
        const { storeCode } = useUrlLanguageParser();
        const storeUrl = computed(() => (storeCode.toUpperCase() === 'CA' ? 'https://ca.zennioptical.com/' : 'https://www.zennioptical.com/'));
        return {
            sendGTMEvent,
            storeUrl,
            GTM_EVENTS,
        };
    },
});
